import { defineStore } from "pinia";

export const useUiStore = defineStore(
  "ui",
  () => {
    const emailVerification = ref("");
    const isBoarding = ref(false);
    const sidebarHidden = ref(false);
    const isModalTopupShow = ref(false);
    const isJoinShow = ref(true);
    const isModalManageBank = ref(false);
    const isModalManageBankWithdraw = ref(false);
    const isModalAddBank = ref(false);
    const isModalChooseBank = ref(false);
    const isModalConfirmWithdraw = ref(false);
    const isShippingEnabled = ref(false);
    const walletAmount = ref(0);

    function $reset() {
      emailVerification.value = "";
      isBoarding.value = false;
      sidebarHidden.value = false;
      isModalTopupShow.value = false;
      isJoinShow.value = true;
      isModalManageBank.value = false;
      isModalManageBankWithdraw.value = false;
      isModalAddBank.value = false;
      isModalChooseBank.value = false;
      isModalConfirmWithdraw.value = false;
    }

    function set(params: any) {
      const myStore = useUiStore();
      const states: any = toRefs(myStore.$state);
      const keys = Object.keys(params);

      keys.forEach((key) => {
        states[key].value = params[key];
      });
    }

    return {
      emailVerification,
      isBoarding,
      sidebarHidden,
      isModalTopupShow,
      isJoinShow,
      isModalManageBank,
      isModalManageBankWithdraw,
      isModalAddBank,
      isModalChooseBank,
      isModalConfirmWithdraw,
      isShippingEnabled,
      walletAmount,
      $reset,
      set,
    };
  },
  {
    persist: true,
  },
);
